import React, { Component } from 'react';
import './HomeMain.css';
import Rodal from 'rodal';
import TabBar from '../generic/TabBar';
import scanIcon from '../../assets/ico_scan.png';
import QrReader from 'react-qr-reader';
import playIcon from '../../assets/play_flat.png';
import axios from 'axios';
import 'rodal/lib/rodal.css';
import Settings from '../settings/Settings';
import History from '../history/History';

class HomeMain extends Component {
  constructor() {
    super();

    this.state = {
      showScannerModal: false,
      showPlayerModal: false,
      showSettings: false,
      showHistory: false,
      showPlayButton: false,
      history: []
    };

    this.manualPlayButton = React.createRef();
    this.audio = new Audio();
    this.audio.onended = this.handlePlayerEnded;

    this.ctaType = null;
    this.ctaURL = null;

    this.isScanning = false;
  }

  setUpStore = () => {
    this.props.db.version(1).stores({
      historyData:
        'id,message,cta,ctaType,cachedContentLoc,cachedContentType,isLastMessage'
    });
  };

  saveData = ({
    message,
    cached_content_loc,
    cached_content_type,
    cta,
    cta_type,
    last_message
  }) => {
    this.props.db.historyData.put({
      id: Math.round(new Date().getTime() / 1000),
      message: message,
      cta: cta,
      ctaType: cta_type,
      cachedContentLoc: cached_content_loc,
      cachedContentType: cached_content_type,
      isLastMessage: last_message
    });
  };

  getHistoryData = () => {
    return this.props.db.historyData.toArray();
  };

  handleSettingsClick = () => {
    this.setState({ showSettings: true });
  };

  handleHistoryClick = () => {
    this.setState({ showHistory: true });
  };

  handleOpenScanner = () => {
    this.setState({ showScannerModal: true });
  };

  handleCloseScanner = () => {
    this.setState({ showScannerModal: false });
  };

  handleClosePlayer = () => {
    this.ctaType = null;
    this.ctaURL = null;

    this.handlePlayerEnded();
  };

  handleCloseSettings = () => {
    this.setState({ showSettings: false });
  };

  handleCloseHistory = () => {
    this.setState({ showHistory: false });
  };

  handleScan = data => {
    if (!this.isScanning && data && this.state.showScannerModal) {
      this.isScanning = true;

      this.setState({
        showScannerModal: false,
        showPlayerModal: true,
        showPlayButton: false
      });

      const url = new URL(data);
      const g = url.searchParams.get('jway:g');
      const a = url.searchParams.get('a');
      const urlString =
        url.origin +
        '/q?g=' +
        g +
        '&a=' +
        a +
        '&androidId=pwa&model=pwa&format=json';

      this.fetchMP3(urlString);
    }
  };

  fetchMP3 = urlString => {
    axios
      .get(urlString)
      .then(response => {
        if (!this.state.showPlayerModal) {
          return;
        }

        this.saveData(response.data);

        this.getHistoryData()
          .then(data => {
            this.setState({ history: data });
          })
          .catch(err => {
            console.error(err);
          });

        this.audio.src =
          response.data.cached_content_loc +
          '?noCache=' +
          Math.floor(Math.random() * 1000000);

        this.audio
          .play()
          .then(() => console.log('Auto-playing audio'))
          .catch(err => {
            if (err.code === 0) {
              console.log('Auto-play not supported, displaying play button.');
              this.setState({ showPlayButton: true });
            }
          });

        this.ctaType = response.data.cta_type;
        this.ctaURL = response.data.cta;
      })
      .catch(error => {
        window.alert(error);
      });
  };

  handleError = err => {
    console.error(err);
    window.alert(err);
  };

  handlePlayerEnded = () => {
    console.log('Player has ended');
    this.isScanning = false;
    this.audio.pause();
    this.setState({ showPlayerModal: false });

    this.handleCtaAction();
  };

  manualPlayAudio = () => {
    this.setState({ showPlayButton: false });
    this.audio.play();
  };

  handleURLQueryData = () => {
    const data = window.location.href;
    const url = new URL(data);
    const g = url.searchParams.get('jway:g');
    const a = url.searchParams.get('a');
    // todo:- change url to production
    const urlString =
      'https://vvicloud.com/q?g=' +
      g +
      '&a=' +
      a +
      '&androidId=pwa&model=pwa&format=json';

    if (g && a) {
      this.setState({
        showPlayerModal: true
      });

      this.fetchMP3(urlString);
    }
  };

  handleCtaAction = () => {
    if (!this.ctaType || !this.ctaURL) {
      return;
    }

    let url = this.ctaURL;
    if (this.ctaType === 'redirect') {
      if (!this.ctaURL.match(/^https?:\/\//i)) {
        url = 'http://' + this.ctaURL;
      }
      window.open(url, '_top');
    } else {
      if (window.confirm(`Do you want to call ${url}?`)) {
        window.open(`tel:${url}`);
      }
    }
  };

  componentDidMount() {
    this.handleURLQueryData();

    this.setUpStore();

    this.getHistoryData()
      .then(data => {
        this.setState({ history: data });
      })
      .catch(err => {
        console.error(err);
      });
  }

  render() {
    const playerRodalStyle = {
      width: '100%',
      height: '100%',
      padding: '0',
      backgroundColor: 'rgba(75%, 76%, 77%, .1)'
    };

    const cameraStyle = {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#000'
    };

    const rodalScreenStyle = { width: '100%', height: '100%', padding: 0 };

    return (
      <div className='container'>
        <Rodal
          visible={this.state.showScannerModal}
          animation='slideUp'
          showCloseButton={false}
          customStyles={rodalScreenStyle}
          onClose={this.handleCloseScanner}
        >
          <div className='navbar-default'>
            <button
              className='close-button'
              onClick={this.handleCloseScanner}
            />
            <h2 className='nav-title'>Scan</h2>
            <button
              className='history-button'
              onClick={this.handleHistoryClick}
            />
          </div>
          <QrReader
            delay={100}
            onError={this.handleError}
            onScan={this.handleScan}
            style={cameraStyle}
          />
        </Rodal>
        <Rodal
          visible={this.state.showPlayerModal}
          animation='fade'
          showCloseButton={false}
          customStyles={playerRodalStyle}
          onClose={this.handleClosePlayer}
          closeMaskOnClick={true}
        >
          <div className='player-loader'></div>
          <button
            className='hidden-player-close-button'
            onClick={this.handleClosePlayer}
          />
          <button
            ref={this.manualPlayButton}
            className='hidden-trigger-button'
            onClick={this.manualPlayAudio}
            style={{ display: this.state.showPlayButton ? 'block' : 'none' }}
          >
            <img
              src={playIcon}
              alt='Icon'
              width='40'
              height='40'
              className='play-btn'
            />
          </button>
        </Rodal>
        {/* Settings */}
        <Rodal
          visible={this.state.showSettings}
          animation='slideUp'
          showCloseButton={false}
          customStyles={rodalScreenStyle}
          onClose={this.handleCloseSettings}
        >
          <div className='navbar-default'>
            <button
              className='close-button'
              onClick={this.handleCloseSettings}
            />
            <h2 className='nav-title'>Settings</h2>
            <Settings />
          </div>
        </Rodal>
        {/* History */}
        <Rodal
          visible={this.state.showHistory}
          animation='slideUp'
          showCloseButton={false}
          customStyles={rodalScreenStyle}
          onClose={this.handleCloseHistory}
        >
          <div className='navbar-default'>
            <button
              className='close-button'
              onClick={this.handleCloseHistory}
            />
            <h2 className='nav-title'>History</h2>
            <History data={this.state.history} />
          </div>
        </Rodal>
        <div className='logo' />
        <div className='background-image' />
        <TabBar
          color='darkgray'
          actions={[this.handleSettingsClick, this.handleHistoryClick]}
        />
        <button className='scan-button' onClick={this.handleOpenScanner}>
          <img src={scanIcon} alt='Icon' width='80' height='80' />
        </button>
      </div>
    );
  }
}

export default HomeMain;

import React, { Component } from 'react';

class TabBarButton extends Component {
  render() {
    const style = {
      backgroundColor: this.props.color,
      flexBasis: '100%',
      height: '100%'
    };

    return (
      <button onClick={this.props.action} style={style}>
        <img src={this.props.icon} alt='Icon' width='20' height='20' />
      </button>
    );
  }
}

export default TabBarButton;

import React from 'react';
import './App.css';
import Dexie from 'dexie';
import HomeMain from '../home/HomeMain';

function App() {
  return <HomeMain db={new Dexie('MyDatabase')} />;
}

export default App;

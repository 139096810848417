import React, { Component, Fragment } from 'react';
import './Settings.css';
import maleIcon from '../../assets/ico_male.png';
import femaleIcon from '../../assets/ico_female.png';

export default class Settings extends Component {
  constructor() {
    super();

    this.state = {
      gender: 'female'
    };
  }

  setGender = gender => {
    localStorage.setItem('voiceGender', gender);
    this.setState({ gender: gender });
  };

  componentDidMount() {
    const voice = localStorage.getItem('voiceGender');
    this.setState({ gender: voice ? voice : 'female' });
  }

  render() {
    return (
      <Fragment>
        <div className='container'>
          <div className='main-content'>
            <img
              src={this.state.gender === 'male' ? maleIcon : femaleIcon}
              alt='Icon'
              className='image-ico settings'
            />
            <p className='subheader'>Select Voice</p>
            <div className='settings-button-container'>
              <button
                onClick={() => this.setGender('male')}
                className='settings-button'
                style={{
                  backgroundColor:
                    this.state.gender === 'male' ? '#32c8ff' : 'darkgray',
                  marginRight: '5px'
                }}
              >
                <h3 className='button-text'>MALE</h3>
              </button>
              <button
                onClick={() => this.setGender('female')}
                className='settings-button'
                style={{
                  backgroundColor:
                    this.state.gender === 'female' ? '#32c8ff' : 'darkgray'
                }}
              >
                <h3 className='button-text'>FEMALE</h3>
              </button>
            </div>
            <div className='background-image-settings' />
          </div>
        </div>
      </Fragment>
    );
  }
}

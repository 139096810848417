import React, { Component } from 'react';
import Rodal from 'rodal';
import moment from 'moment';
import voiceIcon from '../../assets/ico_vv.png';
import playIcon from '../../assets/play_flat.png';
import './History.css';

class History extends Component {
  constructor() {
    super();

    this.state = {
      showPlayer: false
    };

    this.audio = new Audio();
    this.audio.onended = this.onPlayerEnded;

    this.ctaType = null;
    this.ctaURL = null;
  }

  onPlayerEnded = () => {
    console.log('Player has ended');
    this.audio.currentTime = 0;
    this.audio.pause();

    this.setState({ showPlayer: false, showPlayButton: false });

    this.handleCtaAction();
  };

  playVocalization = id => {
    const history = this.props.data.filter(hist => hist.id === id)[0];

    this.audio.src = history.cachedContentLoc;

    this.setState({ showPlayer: true });

    this.audio
      .play()
      .then(() => console.log('Auto-playing audio'))
      .catch(err => {
        if (err.code === 0) {
          console.log('Auto-play not supported, displaying play button.');
          this.setState({ showPlayButton: true });
        }
      });

    this.ctaType = history.ctaType;
    this.ctaURL = history.cta;
  };

  onPlayerStopped = () => {
    console.log('Stopped!!');
    this.ctaType = null;
    this.ctaURL = null;

    this.onPlayerEnded();
  };

  handleCtaAction = () => {
    if (!this.ctaType || !this.ctaURL) {
      return;
    }

    let url = this.ctaURL;
    if (this.ctaType === 'redirect') {
      if (!this.ctaURL.match(/^https?:\/\//i)) {
        url = 'http://' + this.ctaURL;
      }
      window.open(url, '_top');
    } else {
      if (window.confirm(`Do you want to call ${url}?`)) {
        window.open(`tel:${url}`);
      }
    }
  };

  manualPlayAudio = () => {
    this.setState({ showPlayButton: false });
    this.audio.play();
  };

  render() {
    const playerRodalStyle = {
      width: '100%',
      height: '100%',
      padding: '0',
      backgroundColor: 'rgba(75%, 76%, 77%, .1)'
    };

    const rows = this.props.data
      .sort((a, b) => (a.id < b.id ? 1 : -1))
      .map(voc => {
        var day = moment(voc.id * 1000);
        return (
          <tr
            key={voc.id}
            onClick={() => this.playVocalization(voc.id)}
            style={{ paddingBottom: '15px' }}
          >
            <td>
              <img
                src={voiceIcon}
                style={{
                  width: '30px',
                  height: '21px',
                  backgroundSize: 'contain'
                }}
                alt='Icon'
              />
            </td>
            <td
              style={{
                padding: '10px',
                borderBottom: '1px solid #E0E0E0'
              }}
            >
              {voc.message}
              <br />
              <span className='history-date'>
                {day.format('MM/DD/YYYY HH:mm')}
              </span>
            </td>
          </tr>
        );
      });

    return (
      <div style={{ overflow: 'auto', height: '90vh' }}>
        <table style={{ padding: '5px' }}>
          <tbody>{rows}</tbody>
        </table>
        <Rodal
          visible={this.state.showPlayer}
          animation='fade'
          showCloseButton={false}
          customStyles={playerRodalStyle}
          onClose={this.onPlayerStopped}
        >
          <div className='player-loader'></div>
          <button
            className='hidden-player-close-button'
            onClick={this.onPlayerStopped}
          />
          <button
            ref={this.manualPlayButton}
            className='hidden-trigger-button'
            onClick={this.manualPlayAudio}
            style={{ display: this.state.showPlayButton ? 'block' : 'none' }}
          >
            <img
              src={playIcon}
              alt='Icon'
              width='40'
              height='40'
              className='play-btn'
            />
          </button>
        </Rodal>
      </div>
    );
  }
}

export default History;

import React, { Component } from 'react';
import TabBarButton from './TabBarButton';
import gearIcon from '../../assets/ico_gear.png';
import historyIcon from '../../assets/ico_history.png';

class TabBar extends Component {
  render() {
    const mainStyle = {
      backgroundColor: this.props.color
    };

    const divStyle = {
      display: 'flex',
      height: '100%'
    };

    return (
      <div className='tab-bar' style={mainStyle}>
        <div style={divStyle}>
          <TabBarButton
            color='rgba(0,0,0,0)'
            icon={gearIcon}
            action={this.props.actions[0]}
          />
          <TabBarButton
            color='rgba(0,0,0,0)'
            icon={historyIcon}
            action={this.props.actions[1]}
          />
        </div>
      </div>
    );
  }
}

export default TabBar;
